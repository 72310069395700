import { useState, useMemo } from 'react'
import { Tab, Tabs, TextField, Button, Typography, MenuItem, Box, useMediaQuery } from '@mui/material'
import tw, { styled } from 'twin.macro'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { find, propEq, isEmpty } from 'ramda'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useOrientation } from 'react-use'
import { useTheme } from '@mui/material/styles'

import { user } from 'service/userService'
import { crm } from 'service/crmService'

import { useLangStore } from 'store/authAtom'
import { useValidationI18n } from 'utils/validationI18n'
import Loading from 'page/Main/Loading'
import { useUpdateErrorMessages } from 'utils/filter'

const Form = styled.form`
    ${tw`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-8`}
    & .MuiFormLabel-root {
        display: inline-table;
    }
`

const TabsStyle = styled(Tabs)`
    border-bottom: 1px solid #0a173d;
    & .MuiButtonBase-root {
        padding-left: 0;
        align-items: flex-start;
    }
    & .MuiTabs-indicator {
        width: 56px !important;
        height: 4px;
    }
    & .MuiTabs-flexContainer {
        .MuiButtonBase-root {
            min-width: 149px;
            flex: none;
        }
    }
`
const TabWrap = tw.div`w-full`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
}

export default function Main() {
    const { t } = useTranslation()
    const lang = useLangStore((state) => state.lang)
    const { validationSchema } = useValidationI18n()

    const navigate = useNavigate()
    const [current, setCurrent] = useState(0)

    const { enqueueSnackbar } = useSnackbar()

    const { angle } = useOrientation()
    const theme = useTheme()
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const {
        register,
        handleSubmit,
        control,
        getValues,
        formState: { errors },
        trigger,
    } = useForm({
        resolver: yupResolver(
            yup.object({
                emailId: current === 0 && validationSchema.emailId,
                countryCode: current === 1 && validationSchema.countryCode,
                mobileNo: current === 1 && validationSchema.mobileNo,
            }),
        ),
    })

    useUpdateErrorMessages(errors, trigger)

    const { data: countryCodeList } = useQuery({
        queryKey: ['getCountryCodeList', lang],
        queryFn: () => user.getCountryCodeList({ lang }),
        placeholderData: {
            codeList: [
                {
                    code: 852,
                },
            ],
            countryCodeList: [{ name: '', isoCode: '', area: [] }],
        },
        enabled: !!lang,
    })

    const checkProfileExistMutation = useMutation({
        mutationFn: crm.checkProfileExist,
        onSuccess: () => {
            if (current === 0) {
                enqueueSnackbar(t('ForgotPassword.EmailNotExist'), { variant: 'error' })
            } else {
                enqueueSnackbar(t('ForgotPassword.PhoneNteExist'), { variant: 'error' })
            }
        },
        onError: () => {
            const formValues = getValues()
            if (current === 0) {
                navigate('/login/verifyEmail', {
                    state: {
                        payload: {
                            emailId: formValues.emailId,
                        },
                        backRoute: '/login/forgotPassword',
                    },
                })
            } else {
                navigate('/login/verifyPhone', {
                    state: {
                        payload: {
                            countryCode: formValues.countryCode,
                            mobileNo: formValues.mobileNo,
                        },
                        backRoute: '/login/forgotPassword',
                    },
                })
            }
        },
    })

    const handleChange = (_, newValue) => {
        setCurrent(newValue)
    }

    const onContinue = (formData) => {
        checkProfileExistMutation.mutate({
            emailId: formData?.emailId,
            countryCode: formData?.countryCode,
            mobileNo: formData?.mobileNo,
            lang,
            openIdUUID: null,
        })
    }

    return (
        <Form
            onSubmit={handleSubmit(onContinue)}
            autoComplete='off'
            className={`site-grid site-grid--support-medium ${isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''}`}
        >
            <Box className='space-y-3'>
                <Typography variant='h5'>{t('ForgotPassword.ForgotPasswordDisplay')}</Typography>
                <Typography variant='subtitle2'>{t('ForgotPassword.EnterEmailOrPhoneResetPasswordDisplay')}</Typography>
            </Box>

            <TabsStyle
                variant='fullWidth'
                value={current}
                onChange={handleChange}
                textColor='secondary'
                indicatorColor='secondary'
            >
                <Tab className='normal-case' label={t('ForgotPassword.EmailAddressButton')} id={0} />
                <Tab className='normal-case' label={t('ForgotPassword.MobileButton')} id={1} />
            </TabsStyle>

            <TabWrap>
                {current === 0 && (
                    <TextField
                        {...register('emailId', {
                            shouldUnregister: true,
                        })}
                        fullWidth
                        label={t('ForgotPassword.EmailAddressDisplay')}
                        variant='standard'
                        color='secondary'
                        placeholder={t('ForgotPassword.emailHint')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors?.emailId}
                        helperText={errors?.emailId?.message ?? null}
                    />
                )}
                {current === 1 && (
                    <div className='flex space-x-3'>
                        <Controller
                            name='countryCode'
                            control={control}
                            shouldUnregister={true}
                            defaultValue=''
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    select
                                    label={t('ForgotPassword.MobileDisplay')}
                                    variant='standard'
                                    color='secondary'
                                    className='w-[100px]'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    SelectProps={{
                                        MenuProps,
                                        displayEmpty: true,
                                        renderValue: (selected) => {
                                            if (!selected) {
                                                return (
                                                    <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                        {t('Common.Select')}
                                                    </Typography>
                                                )
                                            }
                                            return `+${
                                                find(propEq(selected, 'code'))(countryCodeList?.codeList)?.['code']
                                            }`
                                        },
                                    }}
                                >
                                    {countryCodeList?.codeList?.map((option) => (
                                        <MenuItem key={option.code} value={option.code}>
                                            +{option.code}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                        <TextField
                            {...register('mobileNo', {
                                shouldUnregister: true,
                            })}
                            fullWidth
                            label=' '
                            variant='standard'
                            color='secondary'
                            placeholder={t('ForgotPassword.YourNumberHint')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors?.mobileNo}
                            helperText={errors?.mobileNo?.message ?? null}
                        />
                    </div>
                )}
            </TabWrap>

            <Button variant='contained' className='rounded-full normal-case mt-6' fullWidth type='submit'>
                {t('ForgotPassword.ContinueButton')}
            </Button>
            <Button variant='outlined' className='rounded-full normal-case mt-6' fullWidth onClick={() => navigate(-1)}>
                {t('ForgotPassword.BackButton')}
            </Button>
        </Form>
    )
}
