import { createRef } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// import { Helmet } from 'react-helmet'
import { CssBaseline, Grow, IconButton, SvgIcon } from '@mui/material'
import { zhCN } from '@mui/material/locale'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider, MaterialDesignContent } from 'notistack'
import { Close } from '@mui/icons-material'
import { styled } from 'twin.macro'

import 'resources/i18n'
import './index.css'
import App from './App'
import ThemeProvider from 'theme'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 3,
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
            retry: false,
        },
        mutations: {},
    },
})

const notistackRef = createRef()
const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#231448',
        color: '#FFFFFF',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#FFE7EC',
        color: '#E20030',
    },
}))

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <>
        {/* <Helmet>
            <title>啟德登陸頁</title>
            <meta name='google-site-verification' content='fg7RCuISwCdy8yX11Swd1VONNLPgfP9A2Z2UT6a-2EA' />
            <meta property='og:type' content='website' />
            <meta property='og:title' content='啟德登陸頁' />
            <meta property='og:site_name' content='啟德登陸頁' />
            <meta
                property='og:description'
                content='Kai Tak Sports Park Kai Tak Sports Park supports the three key Government sport development policy objectives: 01 Promote “Sports for All” in the community, providing high quality sports facilities 02 Support elite athletes 03 Make Hong Kong a centre for major international sports events Kai Tak Sports Park will look to inspire the next … Kai Tak Sports Park 查看全文 »'
            />
            <meta property='og:url' content='https://account.ktspsit.com' />

            <meta itemprop='name' content='啟德登陸頁' />
            <meta
                itemprop='description'
                content='Kai Tak Sports Park Kai Tak Sports Park supports the three key Government sport development policy objectives: 01 Promote “Sports for All” in the community, providing high quality sports facilities 02 Support elite athletes 03 Make Hong Kong a centre for major international sports events Kai Tak Sports Park will look to inspire the next … Kai Tak Sports Park 查看全文 »'
            />
            <meta itemprop='url' content='https://account.ktspsit.com' />
            <meta itemprop='keywords' content='啟德登陸頁, 启德登录页, QiDe, 启德, 啟德' />

            <meta name='keywords' content='啟德登陸頁, 启德登录页, QiDe, 启德, 啟德' />
            <meta
                name='description'
                content='Kai Tak Sports Park Kai Tak Sports Park supports the three key Government sport development policy objectives: 01 Promote “Sports for All” in the community, providing high quality sports facilities 02 Support elite athletes 03 Make Hong Kong a centre for major international sports events Kai Tak Sports Park will look to inspire the next … Kai Tak Sports Park 查看全文 »'
            />
        </Helmet> */}
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-right' />
            <ThemeProvider>
                <CssBaseline />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <SnackbarProvider
                        preventDuplicate
                        autoHideDuration={null}
                        Components={{
                            success: StyledMaterialDesignContent,
                            error: StyledMaterialDesignContent,
                        }}
                        TransitionComponent={Grow}
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        ref={notistackRef}
                        action={(key) => (
                            <IconButton onClick={onClickDismiss(key)} size='small' color='inherit'>
                                <Close></Close>
                            </IconButton>
                        )}
                    >
                        <App />
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </QueryClientProvider>
    </>,
)
