import { useState, useEffect, useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { Typography, useMediaQuery, Link as MuiLink } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useLocation, useNavigate, Link as RouteLink } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { isNil } from 'ramda'
import { useEffectOnce } from 'react-use'
import { useSnackbar } from 'notistack'
import { useTranslation, Trans } from 'react-i18next'
import { useOrientation } from 'react-use'
import { useTheme } from '@mui/material/styles'

import { loginReg } from 'service/authService'
import { crm } from 'service/crmService'
import { useLangStore } from 'store/authAtom'
import { decrypt_signUp, encrypt_signUp } from 'utils/crypto'

const Wrap = tw.div`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-4`

const MuiOtpInputStyled = styled(MuiOtpInput)`
    ${tw`flex px-[27.5px] gap-5 self-center`};
    & .MuiInputBase-root {
        border-radius: 12px;
        width: 50px;
        height: 60px;
    }
`

let interval = ''

// 隐藏号码的后四位
const hideLastFourNum = (number) => {
    return !isNil(number) ? number?.slice(0, -4) + '****' : undefined
}

export default function VerifyPhone() {
    const lang = useLangStore((state) => state.lang)
    const { state } = useLocation()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()

    const [otp, setOtp] = useState('')
    const [countdownValue, setCountdownValue] = useState(60)
    const [checkProfileExist, setCheckProfileExist] = useState(true)
    const [userFail, setUserFail] = useState(undefined)

    const { angle } = useOrientation()
    const theme = useTheme()
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const checkProfileExistMutation = useMutation({
        mutationFn: crm.checkProfileExist,
        onSuccess: () => {
            setCheckProfileExist(true)
        },
        onError: () => {
            setCheckProfileExist(false)
        },
    })

    const sendOtpMutation = useMutation({
        mutationFn: loginReg.sendOtp,
        onSuccess: () => {
            setCountdownValue(60)
            interval = setInterval(() => {
                setCountdownValue((prevValue) => prevValue - 1)
            }, 1000)
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const getUserFailMutation = useMutation({
        mutationFn: loginReg.getUserFail,
        onSuccess: (v) => {
            setUserFail({
                ...v,
                mobileNo: decrypt_signUp(v?.mobileNo),
                emailId: decrypt_signUp(v?.emailId),
            })
            sendOtpMutation.mutate({
                countryCode: v?.countryCode,
                lang: v?.languagePreference,
                mobileNo: decrypt_signUp(v?.mobileNo),
                newCountryCode: '',
                newMobileNo: '',
                verifyType: 0,
                sysLang: lang,
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const otpVerifiyMutation = useMutation({
        mutationFn: loginReg.otpVerifiy,
        onSuccess: (res) => {
            let v = userFail ?? state?.payload
            let path = checkProfileExist ? '/login/chooseYourInterest' : '/login/resetPassword'
            navigate(path, {
                state: {
                    payload: {
                        ...v,
                        forGotPasswordKey: checkProfileExist ? undefined : res?.forGotPasswordKey,
                    },
                },
            })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const handleSendOtp = (v) => {
        if (state?.emailId) {
            otpVerifiyMutation.mutate({
                countryCode: userFail?.countryCode,
                lang,
                mobileNo: userFail?.mobileNo,
                verifyCode: v,
            })
        } else {
            // updatePhone & forgotPassword
            otpVerifiyMutation.mutate({
                countryCode: state?.payload?.newCountryCode ?? state?.payload?.countryCode,
                mobileNo: state?.payload?.newMobileNo ?? state?.payload?.mobileNo,
                lang,
                verifyCode: v,
            })
        }
    }

    const onResend = () => {
        if (state?.emailId) {
            sendOtpMutation.mutate({
                countryCode: userFail?.countryCode,
                lang: userFail?.languagePreference,
                mobileNo: userFail?.mobileNo,
                newCountryCode: '',
                newMobileNo: '',
                verifyType: 0,
                sysLang: lang,
            })
        } else {
            // updatePhone & forgotPassword
            sendOtpMutation.mutate({
                countryCode: state?.payload?.countryCode,
                lang: state?.payload?.languagePreference || lang,
                mobileNo: state?.payload?.mobileNo,
                newCountryCode: state?.payload?.newCountryCode || '',
                newMobileNo: state?.payload?.newMobileNo || '',
                verifyType: 0,
                sysLang: lang,
            })
        }
    }

    useEffectOnce(() => {
        if (state?.emailId && state?.userInfoKey) {
            getUserFailMutation.mutate({
                emailId: encrypt_signUp(state.emailId),
                userInfoKey: state.userInfoKey,
                lang,
            })
        } else {
            // updatePhone & forgotPassword

            if (!state?.pass) {
                checkProfileExistMutation.mutate({
                    countryCode: state?.payload?.newCountryCode ?? state?.payload?.countryCode,
                    mobileNo: state?.payload?.newMobileNo ?? state?.payload?.mobileNo,
                    lang,
                })

                sendOtpMutation.mutate({
                    lang: state?.payload?.languagePreference || lang,
                    countryCode: state?.payload?.countryCode,
                    mobileNo: state?.payload?.mobileNo,
                    newCountryCode: state?.payload?.newCountryCode || '',
                    newMobileNo: state?.payload?.newMobileNo || '',
                    verifyType: 0,
                    sysLang: lang,
                })
            } else {
                setCountdownValue(0)
            }
        }
    })

    useEffect(() => {
        if (countdownValue === 0) {
            clearInterval(interval) ////清除的話就可以重置点击
        }
    }, [countdownValue])

    return (
        <Wrap
            className={`site-grid site-grid--support-medium min-h-[318px] ${
                isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''
            }`}
        >
            <Typography variant='h5' gutterBottom>
                {t('PhoneNoVerification.PhoneNoVerificationDisplay')}
            </Typography>

            <Typography variant='subtitle2' gutterBottom>
                {t('PhoneNoVerification.WeJustSentCodeToYourPhoneNoDisplay', {
                    phoneNo: `+${
                        state?.payload?.newCountryCode ?? state?.payload?.countryCode ?? userFail?.countryCode
                    } ${' '} ${
                        hideLastFourNum(state?.payload?.newMobileNo) ??
                        hideLastFourNum(state?.payload?.mobileNo) ??
                        hideLastFourNum(userFail?.mobileNo)
                    }`,
                })}
            </Typography>

            {state?.backRoute !== '/login/forgotPassword' && (
                <Typography variant='subtitle2' gutterBottom>
                    <Trans
                        i18nKey='PhoneNoVerification.IfYourPhoneNoIsIncorrectDisplay'
                        components={[
                            <RouteLink
                                to='/login/updatePhone'
                                state={userFail ?? state?.payload}
                                variant='subtitle2'
                                className='text-primaryRed-ktRed underline'
                            />,
                        ]}
                    />
                </Typography>
            )}

            <MuiOtpInputStyled length={4} value={otp} onChange={setOtp} onComplete={handleSendOtp} autoFocus />

            <Typography className='flex items-center justify-center' variant='subtitle2' gutterBottom>
                {t('PhoneNoVerification.NoReceiveCodeDisplay')}
                <MuiLink
                    disabled={countdownValue !== 0}
                    className={countdownValue !== 0 ? 'text-gray-500 no-underline cursor-wait' : ''}
                    variant='subtitle2'
                    component='button'
                    onClick={onResend}
                >
                    {t('PhoneNoVerification.ResentButton')} ({countdownValue}s)
                </MuiLink>
            </Typography>
        </Wrap>
    )
}
