import { createTheme, SvgIcon, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { zhCN } from '@mui/material/locale'

export default function ThemeProvider({ children }) {
    const {
        i18n: { language },
    } = useTranslation()
    console.log(language)
    const fontFamily = language === 'en_US' ? 'Barlow' : 'MicrosoftJhengHei'

    const theme = createTheme(
        {
            palette: {
                checkboxes: {
                    main: '#231448',
                },
                primary: {
                    main: '#D6093B',
                },
                secondary: {
                    main: '#231448',
                    light: '#E9ECF3',
                    dark: '#4A4B59',
                    contrastText: '#fff',
                },
                error: {
                    main: '#D6093B',
                },
            },
            typography: {
                h2: {
                    fontFamily: [fontFamily, 'sans-serif'].join(','),
                    fontSize: '72px',
                    lineHeight: '86.4px',
                    fontWeight: 600,
                    '@media screen and (min-width: 769px) and (max-width: 1024px)': {
                        fontSize: '48px',
                    },
                },
                h5: {
                    fontFamily: [fontFamily, 'sans-serif'].join(','),
                    fontSize: '28px',
                    fontWeight: 600,
                    lineHeight: '28px',
                    color: '#231448',
                },
                subtitle2: {
                    fontFamily: [fontFamily, 'sans-serif'].join(','),
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#231448',
                },
                button: {
                    fontFamily: [fontFamily, 'sans-serif'].join(','),
                    fontSize: '16px',
                },

                loadding: {
                    fontFamily: [fontFamily, 'sans-serif'].join(','),
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#fff',
                },
                checkboxesText: {
                    fontFamily: [fontFamily, 'sans-serif'].join(','),
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    color: '#231448',
                },

                fontFamily: [fontFamily, 'sans-serif'].join(','),
            },
            components: {
                MuiCheckbox: {
                    defaultProps: { color: 'checkboxes' },
                    styleOverrides: {
                        root: {
                            color: '#C5C5CD',
                        },
                    },
                },
                MuiListSubheader: {
                    styleOverrides: {
                        root: {
                            fontSize: '22px',
                            fontFamily: [fontFamily, 'sans-serif'].join(','),
                            color: '#231448',
                            fontWeight: 600,
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            fontSize: '16px',
                            fontFamily: [fontFamily, 'sans-serif'].join(','),
                            color: '#4A4B59',
                            fontWeight: 500,
                            '&.Mui-selected': {
                                backgroundColor: '#EFEFEF',
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: '#EFEFEF',
                            },
                        },
                    },
                },
                MuiButton: {
                    variants: [
                        {
                            props: { variant: 'customize' }, /// 格外自定义样式
                        },
                    ],
                    styleOverrides: {
                        root: ({ ownerState }) => ({
                            fontSize: '18px',
                            lineHeight: '30px',
                            padding: '8px 22px',
                            boxShadow: 'none',
                            ...(ownerState.variant === 'outlined' && {
                                '&:hover': {
                                    backgroundColor: '#fff',
                                },
                            }),
                            ...(ownerState.variant === 'contained' && {
                                '&:hover': {
                                    backgroundColor: '#D6093B',
                                    boxShadow: 'none',
                                },
                                '&.Mui-disabled': {
                                    color: '#fff',
                                    backgroundColor: '#CDCDCD',
                                },
                            }),
                        }),
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& .MuiFormLabel-root': {
                                fontFamily: [fontFamily, 'sans-serif'].join(','),
                                color: '#231448',
                                fontSize: '16px',
                                fontWeight: 600,
                            },
                            '& .MuiInputBase-root': {
                                fontFamily: [fontFamily, 'sans-serif'].join(','),
                                color: '#231448',
                                fontSize: '16px',
                                fontWeight: 500,
                                '.MuiInputBase-input': {
                                    padding: '8px 0 9px',
                                },
                                '&::before': {
                                    borderBottom: '1px solid #D4D6D9',
                                },
                                '&.Mui-error::before': {
                                    borderBottom: '2px solid #D6093B',
                                },
                                '&::after': {
                                    borderBottom: '2px solid #231448',
                                },
                                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                    borderBottom: '1px solid #231448',
                                },
                                '.MuiSvgIcon-root': {
                                    color: '#231448',
                                    width: '24px',
                                    height: '24px',
                                },
                            },

                            '& .MuiInputBase-input::placeholder': {
                                fontFamily: [fontFamily, 'sans-serif'].join(','),
                                color: '#B1B1B1',
                                opacity: 1,
                                fontSize: '16px',
                                fontWeight: 500,
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                                color: '#231448', // 覆盖 error 状态下的颜色
                            },
                        },
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            fontWeight: 500,
                        },
                    },
                },
                MuiSelect: {
                    defaultProps: {
                        IconComponent: (props) => {
                            return (
                                <SvgIcon {...props}>
                                    <svg
                                        t='1724144720014'
                                        viewBox='0 0 1024 1024'
                                        version='1.1'
                                        xmlns='http://www.w3.org/2000/svg'
                                        p-id='1062'
                                    >
                                        <path
                                            d='M810.666667 413.866667L512 725.333333 213.333333 413.866667l28.629334-29.866667L512 665.6 782.037333 384l28.629334 29.866667z'
                                            fill='currentColor'
                                            p-id='1063'
                                        ></path>
                                    </svg>
                                </SvgIcon>
                            )
                        },
                    },
                },
                // MuiCssBaseline: {
                //     styleOverrides: (themeParam) => `
                //       h1 {
                //         color: ${themeParam.palette.primary.success.main};
                //       }
                //     `,
                // },
                MuiCssBaseline: {
                    styleOverrides: (themeParam) => `
                body {
                    font-family: ${themeParam.typography.fontFamily};
                    font-weight: 500;
                    color: #231448
                }
              `,
                },
            },
            status: {},

            breakpoints: {
                values: {
                    xs: 0,
                    sm: 431,
                    md: 769,
                    lg: 1025,
                    xl: 1441,

                    flatbed: 1024, //isHorizontal && phone
                },
            },
        },
        zhCN, ////多语言可以在这更改组件语言类型
    )

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
