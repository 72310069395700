import tw, { styled } from 'twin.macro'
import { useMemo } from 'react'
import { Typography, Button, Box, TextField, MenuItem, useMediaQuery } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useOrientation } from 'react-use'
import { find, propEq } from 'ramda'
import { useTheme } from '@mui/material/styles'

import { user } from 'service/userService'
import { crm } from 'service/crmService'
import { useLangStore } from 'store/authAtom'
import { useValidationI18n } from 'utils/validationI18n'
import { useUpdateErrorMessages } from 'utils/filter'

const Form = styled.form`
    ${tw`self-center px-[60px] py-[42px] my-[60px] bg-white shadow-lg flex flex-col space-y-8`}
    & .MuiFormLabel-root {
        font-weight: 600;
        display: inline-table;
        color: #231448;
    }
`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
}

export default function UpdatePhone() {
    const { t } = useTranslation()
    const { state } = useLocation()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const { validationSchema } = useValidationI18n()
    const lang = useLangStore((state) => state.lang)

    const { angle } = useOrientation()
    const theme = useTheme()
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const { data: countryCodeList } = useQuery({
        queryKey: ['getCountryCodeList', lang],
        queryFn: () => user.getCountryCodeList({ lang }),
        placeholderData: {
            codeList: [
                {
                    code: 852,
                },
            ],
            countryCodeList: [{ name: '', isoCode: '', area: [] }],
        },
        enabled: !!lang,
    })

    const {
        register,
        handleSubmit,
        control,
        getValues,
        reset,
        formState: { errors },
        trigger,
    } = useForm({
        resolver: yupResolver(
            yup.object({
                countryCode: validationSchema.countryCode,
                mobileNo: validationSchema.mobileNo,
            }),
        ),
    })

    useUpdateErrorMessages(errors, trigger)

    const checkProfileExistMutation = useMutation({
        mutationFn: crm.checkProfileExist,
        onSuccess: () => {
            const formData = getValues()
            navigate('/login/verifyPhone', {
                state: {
                    payload: {
                        ...state,
                        newCountryCode: formData?.countryCode,
                        newMobileNo: formData?.mobileNo,
                    },
                },
            })
        },
        onError: (e) => {
            reset()
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const handleUpdate = (formData) => {
        checkProfileExistMutation.mutate({
            countryCode: formData?.countryCode,
            mobileNo: formData?.mobileNo,
            lang,
        })
    }

    const handleBack = () => {
        navigate('/login/verifyPhone', {
            state: {
                payload: {
                    ...state,
                    newCountryCode: null,
                    newMobileNo: null,
                },
                pass: true,
            },
        })
    }

    return (
        <Form
            onSubmit={handleSubmit(handleUpdate)}
            autoComplete='off'
            className={`site-grid site-grid--support-medium min-h-[307px] ${
                isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''
            }`}
        >
            <Typography variant='h5'>{t('UpdatePhone.YourPhoneNoDisplay')}</Typography>

            <Box className='flex space-x-3'>
                <Controller
                    name='countryCode'
                    control={control}
                    shouldUnregister={true}
                    defaultValue=''
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            select
                            label={t('UpdatePhone.MobileDisplay')}
                            variant='standard'
                            color='secondary'
                            className='w-[100px]'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error}
                            helperText={error ? error.message : null}
                            SelectProps={{
                                MenuProps,
                                displayEmpty: true,
                                renderValue: (selected) => {
                                    if (!selected) {
                                        return (
                                            <Typography className='text-secondary-midGray' variant='subtitle2'>
                                                {t('Common.Select')}
                                            </Typography>
                                        )
                                    }
                                    return `+${find(propEq(selected, 'code'))(countryCodeList?.codeList)?.['code']}`
                                },
                            }}
                        >
                            {countryCodeList?.codeList?.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    +{option.code}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                <TextField
                    {...register('mobileNo', {
                        shouldUnregister: true,
                    })}
                    fullWidth
                    label=' '
                    variant='standard'
                    color='secondary'
                    placeholder={t('UpdatePhone.YourNumberHint')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors?.mobileNo}
                    helperText={errors?.mobileNo?.message ?? null}
                />
            </Box>
            <Button variant='contained' size='large' className='rounded-full normal-case mt-6' fullWidth type='submit'>
                {t('UpdatePhone.SendVerificationCodeButton')}
            </Button>
            <Button className='rounded-full normal-case mt-6' variant='outlined' fullWidth onClick={handleBack}>
                {t('Common.Back')}
            </Button>
        </Form>
    )
}
