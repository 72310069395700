// registration
import { useMemo } from 'react'
import { Button, Typography, useMediaQuery, Box } from '@mui/material'
import tw, { styled } from 'twin.macro'
import { Link as RouteLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { useOrientation } from 'react-use'

import SignUpCom from './component/SignUpCom'
import { useIASMStore } from 'store/authAtom'

const Wrap = tw.div`px-[60px] py-[42px] mt-[60px] flex flex-col bg-white shadow-lg space-y-3`

const FontWrap = tw.div`text-[#FFF] whitespace-pre-line`

const FormWrap = styled.div`
    ${tw`overflow-y-auto space-y-8 mt-8!`}
`

export default function SignUp() {
    const { t } = useTranslation()

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.between('md', 'lg')) /// padding 42px
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed')) /// padding 60px
    const matchesUpMD = useMediaQuery(theme.breakpoints.up('md'))
    const matchesDownSM = useMediaQuery(theme.breakpoints.down('sm')) /// padding: 24px 16px;

    const tokenInfo = useIASMStore((state) => state.tokenInfo)

    return (
        <>
            {((isHorizontal && matchesLaptopUp) || (!isHorizontal && matchesUpMD)) && (
                <FontWrap className='font-wrap'>
                    <Typography variant='h2'>{t('Common.bgFont')}</Typography>
                </FontWrap>
            )}

            <Wrap
                className={`form-wrap align-self ${matches ? 'registration' : 'site-grid site-grid--support-medium'} ${
                    isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''
                }`}
            >
                <Typography variant='h5'>{t('SignUpPage.SignUpDisplay')}</Typography>
                <Typography variant='subtitle2' gutterBottom>
                    {t('SignUpPage.HelloCreateTheAccountNowDisplay')}
                </Typography>

                {!!tokenInfo && (
                    <Box
                        className={[
                            'relative bg-[#E9ECF3] py-[12px] pl-[30px] pr-[60px] mt-6 whitespace-pre-line',
                            `${matches ? 'mx-[-42px]' : matchesDownSM ? 'mx-[-16px]' : 'mx-[-60px]'}`,
                        ]}
                    >
                        <Typography variant='subtitle2' gutterBottom className='text-[12px] leading-[18px]'>
                            {t('SignUpPage.IASMDisplay')}
                        </Typography>
                    </Box>
                )}

                <FormWrap>
                    <SignUpCom />

                    <Button
                        variant='contained'
                        size='large'
                        className='rounded-full normal-case'
                        fullWidth
                        type='submit'
                        form='signUpForm'
                    >
                        {t('SignUpPage.ContinueButton')}
                    </Button>
                    <Typography variant='subtitle2'>
                        {t('SignUpPage.AlreadyHaveAaccountDisplay')}{' '}
                        <RouteLink to='/login/signIn' className='text-primaryRed-ktRed underline'>
                            {t('SignUpPage.LoginNowButton')}
                        </RouteLink>
                    </Typography>
                </FormWrap>
            </Wrap>
        </>
    )
}
