import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Button, IconButton, useMediaQuery, Menu, MenuItem } from '@mui/material'
import { useShallow } from 'zustand/react/shallow'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material/styles'

import { useLangStore } from 'store/authAtom'
import headerLogo from 'resources/images/headerLogo.png'
import LangCom from './LangCom'
import { useDisclosure } from 'utils/useDisclosure'

const Wrap = styled.div`
    ${tw`flex items-center justify-between`}
    ${({ matchesUpLG }) => matchesUpLG && tw`px-[50px] h-[108px]`}
    ${({ matchesMDLG }) => matchesMDLG && tw`pl-[20px] pr-[12px] h-[85px]`}
    ${({ matchesDownSM }) => matchesDownSM && tw`pl-[31.5px] pr-[8px] h-[108px]`}

    width: 100%;
    background: linear-gradient(
        72.63deg,
        rgba(143, 0, 68, 1) 11.91%,
        rgba(188, 0, 60, 1) 37.3%,
        rgba(226, 0, 48, 1) 63.49%,
        rgba(255, 22, 54, 1) 88.09%
    );
`

const Img = tw.img`object-contain cursor-pointer lg:w-[148px] lg:h-[66.52px] w-[95px] h-[43px]`

export default function AppBar() {
    const { t } = useTranslation()
    const theme = useTheme()

    const matchesDownLG = useMediaQuery(theme.breakpoints.down('lg')) // 1024

    const matchesUpLG = useMediaQuery(theme.breakpoints.up('lg')) // 1025
    const matchesMDLG = useMediaQuery(theme.breakpoints.between('sm', 'lg')) //431 - 1024
    const matchesDownSM = useMediaQuery(theme.breakpoints.down('sm')) // 430

    const { isOpen, onClose, onOpen } = useDisclosure()

    const { lang, redirectUrl } = useLangStore(
        useShallow(({ lang, redirectUrl }) => ({
            lang,
            redirectUrl,
        })),
    )

    const [anchorEl, setAnchorEl] = useState(null)

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget)
        onOpen()
    }
    const handleCLose = () => {
        setAnchorEl(null)
        onClose()
    }

    const linkToLanding = () => {
        window.location.href = `${process.env.REACT_APP_WEBSITE}?lang=${lang}`
    }

    const login = () => {
        window.location.href =
            process.env.NODE_ENV === 'development'
                ? `http://localhost:3001/login/signIn?lang=${lang}&redirectUrl=http://localhost:3000`
                : `${process.env.REACT_APP_CIAM}?lang=${lang}&redirectUrl=${process.env.REACT_APP_WEBSITE}`
    }

    useEffect(() => {
        if (!matchesDownLG) {
            onClose()
            setAnchorEl(null)
        }
    }, [matchesDownLG])

    return (
        <Wrap matchesUpLG={matchesUpLG} matchesMDLG={matchesMDLG} matchesDownSM={matchesDownSM}>
            <Img src={headerLogo} onClick={linkToLanding} />

            {matchesDownLG ? (
                <div>
                    <LangCom />

                    <IconButton size='middle' onClick={handleOpen}>
                        <MenuIcon className='text-white' />
                    </IconButton>

                    <Menu anchorEl={anchorEl} open={isOpen} onClose={handleCLose}>
                        <MenuItem onClick={login}>{t('navigationBar.loginBtn')}</MenuItem>
                    </Menu>
                </div>
            ) : (
                <div className='space-x-[24px]'>
                    <LangCom />
                    <Button
                        className='text-base normal-case rounded-[100px] text-[#fff] border-[#fff] border border-solid'
                        variant='customize'
                        onClick={login}
                    >
                        {t('navigationBar.loginBtn')}
                    </Button>
                </div>
            )}
        </Wrap>
    )
}
