import { http } from 'utils/http'

/**
 * Login and Registration API
 */
export const loginReg = {
    login: (payload) => http.post('/loginReg/login', payload),
    getTitle: (payload) => http.post('/loginReg/getTitle', payload),
    getInterest: (payload) => http.post('/loginReg/getInterest', payload),
    registration: (payload) => http.post('/loginReg/registration', payload),
    emailVerifiy: (payload) => http.post('/loginReg/emailVerifiy', payload),
    sendEmail: (payload) => http.post('/loginReg/sendEmail', payload),
    sendOtp: (payload) => http.post('/loginReg/sendOtp', payload),
    otpVerifiy: (payload) => http.post('/loginReg/otpVerify', payload),
    getUserFail: (payload) => http.post('/loginReg/getUserFail', payload),
    addUserFail: (payload) => http.post('/loginReg/addUserFail', payload),
}