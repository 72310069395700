import { create } from 'zustand'

// 全局多语言设置
const langs = ['en_US', 'zh_CN', 'zh_HK']
export const useLangStore = create((set) => ({
    lang: langs.includes(localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en_US',
    redirectUrl: '',
    setLang: (v) => set({ lang: v }),
    setRedirectUrl: (v) => set({ redirectUrl: v }),
}))

export const useIASMStore = create((set) => ({
    tokenInfo: null,
    setTokenInfo: (v) => set({ tokenInfo: v }),
}))
