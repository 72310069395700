import { useState, useMemo } from 'react'
import {
    Tab,
    Tabs,
    TextField,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    MenuItem,
    useMediaQuery,
    Divider,
} from '@mui/material'
import tw, { styled } from 'twin.macro'
import { useForm, Controller } from 'react-hook-form'
import { useTheme } from '@mui/material/styles'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Link as RouteLink } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useOrientation } from 'react-use'
import { find, propEq } from 'ramda'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

import { useLangStore } from 'store/authAtom'
import { loginReg } from 'service/authService'
import { user } from 'service/userService'
import { encrypt } from 'utils/crypto'
import { useValidationI18n } from 'utils/validationI18n'
import { iasmLangObj } from 'utils/staticData'
import { useUpdateErrorMessages } from 'utils/filter'

import ViewOff from 'resources/svg/view_off.svg'
import View from 'resources/svg/view.svg'
import iamsmartImg from 'resources/images/iamsmart.png'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
}

const Wrap = tw.div`px-[60px] py-[42px] mt-[60px] flex flex-col bg-white shadow-lg space-y-3`
const Form = styled.form`
    ${tw`space-y-4 w-full !mt-8 relative`}
    & .MuiFormLabel-root {
        display: inline-table;
    }
`
const ImgIcon = tw.img``
const TabsStyle = styled(Tabs)`
    border-bottom: 1px solid #0a173d;
    & .MuiButtonBase-root {
        padding-left: 0;
        align-items: flex-start;
    }
    & .MuiTabs-indicator {
        width: 56px !important;
        height: 4px;
    }
    & .MuiTabs-flexContainer {
        .MuiButtonBase-root {
            min-width: 149px;
            flex: none;
        }
    }
`
const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#2B7366',
    '&:hover': {
        backgroundColor: '#2B7366',
    },
}))

const FontWrap = tw.div`text-[#FFF] whitespace-pre-line`

export default function SignIn() {
    const { t } = useTranslation()
    const { validationSchema, requiredValidationsSchema } = useValidationI18n()

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const matchesUpMD = useMediaQuery(theme.breakpoints.up('md'))
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const { enqueueSnackbar } = useSnackbar()

    const [current, setCurrent] = useState(0)
    const [showPassword, setShowPassword] = useState(false)

    const { lang, redirectUrl } = useLangStore(
        useShallow(({ lang, redirectUrl }) => ({
            lang,
            redirectUrl,
        })),
    )

    const { data: countryCodeList } = useQuery({
        queryKey: ['getCountryCodeList', lang],
        queryFn: () => user.getCountryCodeList({ lang }),
        placeholderData: {
            codeList: [
                {
                    code: 852,
                },
            ],
            countryCodeList: [{ name: '', isoCode: '', area: [] }],
        },
        enabled: !!lang,
    })

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        trigger,
    } = useForm({
        defaultValues: {
            emailId: '',
            countryCode: '',
            mobileNo: '',
            password: '',
        },
        resolver: yupResolver(
            yup.object({
                emailId: current === 0 && validationSchema.emailId,
                countryCode: current === 1 && validationSchema.countryCode,
                mobileNo: current === 1 && validationSchema.mobileNo,
                password: requiredValidationsSchema.password,
            }),
        ),
    })

    useUpdateErrorMessages(errors, trigger)

    const loginMutation = useMutation({
        mutationFn: loginReg.login,
        onSuccess: (res) => {
            const format = 'DD/MM/YYYY HH:mm:ss'
            document.cookie = `ktspSsoAccessToken=${encrypt(res.accessToken)}; expires=${dayjs(
                res.accessTokenExpired,
                format,
            )
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`

            document.cookie = `ktspSsoRefreshToken=${encrypt(res.refreshToken)}; expires=${dayjs(
                res.refreshTokenExpired,
                format,
            )
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`

            document.cookie = `session=${encrypt(res.session)}; expires=${dayjs(res.refreshTokenExpired, format)
                .utc()
                .toString()};Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`

            document.cookie = `sessionKey=${encrypt(res?.sessionKey)}; expires=${dayjs(res.refreshTokenExpired, format)
                .utc()
                .toString()};Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            window.location.href =
                (redirectUrl && `${redirectUrl}?lang=${lang}`) || `${process.env.REACT_APP_WEBSITE}?lang=${lang}`

            // document.cookie = `ktspSsoAccessToken=${encrypt(res.accessToken)}; expires=${dayjs(
            //     res.accessTokenExpired,
            //     format,
            // )
            //     .utc()
            //     .toString()}; path=/`
            // document.cookie = `ktspSsoRefreshToken=${encrypt(res.refreshToken)}; expires=${dayjs(
            //     res.refreshTokenExpired,
            //     format,
            // )
            //     .utc()
            //     .toString()}; path=/`
            // document.cookie = `session=${encrypt(res.session)}; path=/`
            // document.cookie = `sessionKey=${encrypt(res.sessionKey)}; path=/`
            // document.cookie = `memberId=${encrypt(res.memberId)}; path=/`

            // window.location.href = `http://localhost:3000/landing?lang=${lang}`
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const handleChange = (_, newValue) => {
        setCurrent(newValue)
        reset()
    }

    const onSubmit = (formData) => {
        loginMutation.mutate({
            deviceId: '',
            platform: 'web',
            lang,
            emailId: formData?.emailId,
            password: formData?.password,
            countryCode: formData?.countryCode,
            mobileNo: formData?.mobileNo,
            openIdUUID: null,
        })
    }

    const goiAMSmart = () => {
        document.cookie = `lang=${lang}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
        window.location.href = `${process.env.REACT_APP_ISMDOMAINS}/api/v1/auth/getQR?clientID=${process.env.REACT_APP_ISM_CLIENTID}&responseType=code&source=PC_Browser&redirectURI=${process.env.REACT_APP_CIAM}/callBackRedirectURI&scope=eidapi_auth%20eidapi_formFilling&lang=${iasmLangObj[lang]}`
    }

    const goLanding = () => {
        window.location.href = `${process.env.REACT_APP_WEBSITE}?lang=${lang}`
    }

    return (
        <>
            {((isHorizontal && matchesLaptopUp) || (!isHorizontal && matchesUpMD)) && (
                <FontWrap className='font-wrap'>
                    <Typography variant='h2'>{t('Common.bgFont')}</Typography>
                </FontWrap>
            )}

            <Wrap
                className={`form-wrap align-self z-10 ${
                    matches ? 'registration' : 'site-grid site-grid--support-medium'
                } ${isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''}`}
            >
                <Typography variant='h5'>{t('SignIn.title')}</Typography>

                <TabsStyle
                    variant='fullWidth'
                    value={current}
                    onChange={handleChange}
                    textColor='secondary'
                    indicatorColor='secondary'
                >
                    <Tab className='normal-case' label={t('SignIn.Email address')} value={0} />
                    <Tab className='normal-case' label={t('SignIn.PhoneNo')} value={1} />
                </TabsStyle>

                <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                    {current === 0 && (
                        <TextField
                            {...register('emailId', {
                                shouldUnregister: true,
                            })}
                            fullWidth
                            label={t('SignIn.EmailAddressDisplay')}
                            variant='standard'
                            color='secondary'
                            placeholder={t('SignIn.emailHint')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors?.emailId}
                            helperText={errors?.emailId?.message ?? null}
                        />
                    )}

                    {current === 1 && (
                        <div className='flex space-x-3'>
                            <Controller
                                name='countryCode'
                                control={control}
                                shouldUnregister={true}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label={t('SignIn.MobileDisplay')}
                                        variant='standard'
                                        color='secondary'
                                        className='min-w-[100px]'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        SelectProps={{
                                            MenuProps,
                                            displayEmpty: true,
                                            renderValue: (selected) => {
                                                if (!selected) {
                                                    return (
                                                        <Typography
                                                            className='text-secondary-midGray'
                                                            variant='subtitle2'
                                                        >
                                                            {t('Common.Select')}
                                                        </Typography>
                                                    )
                                                }
                                                return `+${
                                                    find(propEq(selected, 'code'))(countryCodeList?.codeList)?.['code']
                                                }`
                                            },
                                        }}
                                    >
                                        {countryCodeList?.codeList?.map((option) => (
                                            <MenuItem key={option.code} value={option.code}>
                                                +{option.code}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />

                            <TextField
                                {...register('mobileNo', {
                                    shouldUnregister: true,
                                })}
                                fullWidth
                                label=' '
                                variant='standard'
                                color='secondary'
                                placeholder={t('SignIn.YourNumberHint')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.mobileNo}
                                helperText={errors?.mobileNo?.message ?? null}
                            />
                        </div>
                    )}

                    <TextField
                        {...register('password')}
                        fullWidth
                        label={t('SignIn.PasswordDisplay')}
                        variant='standard'
                        type={showPassword ? 'text' : 'password'}
                        color='secondary'
                        placeholder={t('SignIn.PasswordHint')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!errors?.password}
                        helperText={errors?.password?.message ?? null}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end' className='w-[50px]'>
                                    <IconButton
                                        aria-label='toggle password visibility'
                                        onClick={() => setShowPassword((show) => !show)}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {!showPassword ? <ImgIcon src={ViewOff} /> : <ImgIcon src={View} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Button
                        variant='contained'
                        size='large'
                        className='rounded-full normal-case mt-6'
                        fullWidth
                        type='submit'
                    >
                        {t('SignIn.LoginButton')}
                    </Button>
                </Form>

                <ColorButton
                    variant='contained'
                    size='large'
                    className='rounded-full normal-case mt-4 mb-3'
                    fullWidth
                    color='success'
                    onClick={goiAMSmart}
                    startIcon={<img alt='' className='w-5 h-5 object-contain mr-1' src={iamsmartImg} />}
                >
                    {t('SignIn.Log in with iAM Smart')}
                </ColorButton>
                <Typography variant='subtitle2' gutterBottom>
                    <RouteLink to='/login/forgotPassword' className='text-primaryRed-ktRed underline'>
                        {t('SignIn.Forgot Password')}
                    </RouteLink>
                </Typography>
                <Typography variant='subtitle2' gutterBottom>
                    {t('SignIn.NotAccount')}{' '}
                    <RouteLink to='/login/signUp' className='text-primaryRed-ktRed underline'>
                        {t('SignIn.Sign up now')}
                    </RouteLink>
                </Typography>
                <Divider className='mt-[22px]' />
                <span onClick={goLanding} className='text-primaryRed-ktRed cursor-pointer text-center mt-4 underline'>
                    {t('SignIn.ContinueAsAGuest')}
                </span>
            </Wrap>
        </>
    )
}
