import React, { useEffect } from 'react'
import tw from 'twin.macro'
import { useSearchParams } from 'react-router-dom'
import { CircularProgress, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { isEmpty, indexOf } from 'ramda'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { iamsmart } from 'service/iasmMartService'
import { crm } from 'service/crmService'
import { loginReg } from 'service/authService'
import { encrypt } from 'utils/crypto'
import { useLangStore, useIASMStore } from 'store/authAtom'
import { IASMError } from 'utils/staticData'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

const Wrap = tw.div`h-full flex flex-col items-center justify-center space-y-5`

export default function CallBackRedirectURI() {
    const [searchParams] = useSearchParams()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()

    const { lang, redirectUrl } = useLangStore(
        useShallow(({ lang, redirectUrl }) => ({
            lang,
            redirectUrl,
        })),
    )

    const setTokenInfo = useIASMStore((state) => state.setTokenInfo)

    const { data, isError } = useQuery({
        queryKey: ['iasmGetToken'],
        queryFn: () =>
            iamsmart.getToken({
                code: searchParams.get('code'),
                grantType: 'authorization_code',
            }),
        enabled: !!searchParams.get('code'),
    })

    const loginMutation = useMutation({
        mutationFn: loginReg.login,
        onSuccess: (res) => {
            const format = 'DD/MM/YYYY HH:mm:ss'
            document.cookie = `ktspSsoAccessToken=${encrypt(res.accessToken)}; expires=${dayjs(
                res.accessTokenExpired,
                format,
            )
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            document.cookie = `ktspSsoRefreshToken=${encrypt(res.refreshToken)}; expires=${dayjs(
                res.refreshTokenExpired,
                format,
            )
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            document.cookie = `session=${encrypt(res.session)};expires=${dayjs(res.refreshTokenExpired, format)
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            document.cookie = `sessionKey=${encrypt(res?.sessionKey)};expires=${dayjs(res.refreshTokenExpired, format)
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            window.location.href =
                (redirectUrl && `${redirectUrl}?lang=${lang}`) || `${process.env.REACT_APP_WEBSITE}?lang=${lang}`

            // document.cookie = `accessToken=${encrypt(res.accessToken)}; expires=${dayjs(res.accessTokenExpired, format)
            //     .utc()
            //     .toString()}; path=/`
            // document.cookie = `refreshToken=${encrypt(res.refreshToken)}; expires=${dayjs(
            //     res.refreshTokenExpired,
            //     format,
            // )
            //     .utc()
            //     .toString()}; path=/`
            // document.cookie = `session=${encrypt(res.session)}; path=/`
            // document.cookie = `memberId=${encrypt(res.memberId)}; path=/`

            // window.location.href = `http://localhost:3000/landing?lang=${lang}`
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const checkProfileExistMutation = useMutation({
        mutationFn: crm.checkProfileExist,
        onSuccess: () => {
            navigate('/noRecordFound')
        },
        onError: () => {
            loginMutation.mutate({
                deviceId: '',
                platform: 'web',
                emailId: '',
                password: '',
                countryCode: '',
                mobileNo: '',
                openIdUUID: data?.openID,
            })
        },
    })

    useEffect(() => {
        if (!!data) {
            setTokenInfo(data)
            checkProfileExistMutation.mutate({
                countryCode: '',
                emailId: '',
                lang,
                mobileNo: '',
                openIdUUID: data?.openID,
            })
        }
        if (isError) {
            setTokenInfo(null)
            navigate('/')
        }
    }, [data, isError])

    useEffect(() => {
        if (indexOf(searchParams.get('error_code'), IASMError) !== -1) {
            const code = searchParams.get('error_code')

            // window.location.href =
            //     (redirectUrl && `${redirectUrl}?lang=${lang} `) || `${process.env.REACT_APP_WEBSITE}?lang=${lang} `
            if (code === 'D40000' || code === 'D40001' || code === 'D50001') {
                enqueueSnackbar(t('ErrorCode.D50001'), { variant: 'error' })
            }
            if (code === 'D40002' || code === 'D50002') {
                enqueueSnackbar(t('ErrorCode.D50002'), { variant: 'error' })
            }
            if (code === 'D40003' || code === 'D50003' || code === 'D40004') {
                enqueueSnackbar(t('ErrorCode.D50003'), { variant: 'error' })
            }
            navigate('/')
        }
    }, [searchParams.get('error_code')])

    return (
        <Wrap>
            <CircularProgress className='text-white !w-[76px] !h-[76px]' />
            <Typography variant='loadding'>{t('LoginWithIAMSmart.Loading')}</Typography>
        </Wrap>
    )
}
