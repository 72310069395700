import { useState, useEffect, useMemo } from 'react'
import tw, { styled } from 'twin.macro'
import { Typography, Button, CircularProgress, Box, Link, useMediaQuery, Backdrop } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { filter, length, map } from 'ramda'
import { useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useShallow } from 'zustand/react/shallow'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { useOrientation } from 'react-use'

import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import tick from 'resources/svg/tick.svg'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'

import { loginReg } from 'service/authService'
import { encrypt } from 'utils/crypto'
import { useLangStore } from 'store/authAtom'
import Loading from 'page/Main/Loading'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

const Wrap = tw.div`self-center bg-white shadow-lg space-y-4 overflow-y-auto table`

const Img = styled.img`
    ${tw`rounded-xl object-cover w-full h-full cursor-pointer`}
    ${(props) => (props.isSelect ? tw` rounded-2xl relative outline outline-4 outline-red-700` : '')}
`
const ImgContainer = styled.div`
    ${(props) =>
        props.isSelect &&
        `
            &::before {
                content: "";
                position: absolute;
                top: -8px;
                right: -8px;
                background-image: url(${tick});
                background-size: contain;
                background-position: center;
                z-index: 999;
                border-radius: 9999px;
                width: 30px;
                height: 30px;
                border: 4px solid #D6093B;
                background-color: #D6093B;
            }
    `}
`
const ImageListItemTw = styled(({ isSelect, ...rest }) => <ImageListItem {...rest} />)`
    padding-top: 10px;
    padding-right: 10px;
    ${(props) =>
        props.isSelect &&
        `
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      background-image: url(${tick});
      background-size: contain;
      background-position: center;
      z-index: 999;
      border-radius: 9999px;
      width: 30px;
      height: 30px;
      border: 4px solid #D6093B;
      background-color: #D6093B;
    }
  `}
`

export default function ChooseYourInterest() {
    const { t } = useTranslation()
    const [interestList, setInterestList] = useState([])

    const { state } = useLocation()
    const { enqueueSnackbar } = useSnackbar()

    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const { lang, redirectUrl } = useLangStore(
        useShallow(({ lang, redirectUrl }) => ({
            lang,
            redirectUrl,
        })),
    )

    const { data, isPending } = useQuery({
        queryKey: ['getInterest', lang],
        queryFn: () =>
            loginReg.getInterest({
                lang,
            }),
        select: (res) => {
            return map((item) => ({ ...item, isSelect: false }), res)
        },
    })

    const registrationMutation = useMutation({
        mutationFn: loginReg.registration,
        onSuccess: (res) => {
            const format = 'DD/MM/YYYY HH:mm:ss'
            document.cookie = `ktspSsoAccessToken=${encrypt(res.accessToken)}; expires=${dayjs(
                res.accessTokenExpired,
                format,
            )
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            document.cookie = `ktspSsoRefreshToken=${encrypt(res.refreshToken)}; expires=${dayjs(
                res.refreshTokenExpired,
                format,
            )
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            document.cookie = `session=${encrypt(res.session)};expires=${dayjs(res.refreshTokenExpired, format)
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            document.cookie = `sessionKey=${encrypt(res?.sessionKey)};expires=${dayjs(res.refreshTokenExpired, format)
                .utc()
                .toString()}; Secure; SameSite=None; domain=${process.env.REACT_APP_DOMAINS}; path=/`
            // document.cookie = `memberId=${encrypt(res.memberId)}; Secure; SameSite=None; domain=${
            //     process.env.REACT_APP_DOMAINS
            // }; path=/`
            window.location.href =
                (redirectUrl && `${redirectUrl}?lang=${lang}`) || `${process.env.REACT_APP_WEBSITE}?lang=${lang}`

            // document.cookie = `ktspSsoAccessToken=${encrypt(res.accessToken)}; expires=${dayjs(res.accessTokenExpired, format)
            //     .utc()
            //     .toString()}; path=/`
            // document.cookie = `ktspSsoRefreshToken=${encrypt(res.refreshToken)}; expires=${dayjs(
            //     res.refreshTokenExpired,
            //     format,
            // )
            //     .utc()
            //     .toString()}; path=/`
            // document.cookie = `session=${encrypt(res.session)}; path=/`
            // document.cookie = `memberId=${encrypt(res.memberId)}; path=/`
            // window.location.href = `http://localhost:3000/landing?lang=${lang}`
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onSelect = (item) => {
        setInterestList((list) => {
            return map((v) => {
                if (v.code === item.code) {
                    return {
                        ...v,
                        isSelect: !v.isSelect,
                    }
                } else {
                    return {
                        ...v,
                    }
                }
            }, list)
        })
    }

    const onSubmit = () => {
        let selectedInterestList = filter((v) => v.isSelect === true, interestList)
        registrationMutation.mutate({
            ...state?.payload,
            activityInterest: map((v) => ({ interestCode: v.code }), selectedInterestList),
            plateType: 'web',
            countryCode: state?.payload?.newCountryCode ?? state?.payload?.countryCode,
            mobileNo: state?.payload?.newMobileNo ?? state?.payload?.mobileNo,
            newMobileNo: undefined,
            newCountryCode: undefined,
            confirmPassword: undefined,
        })
    }

    const handleSkip = () => {
        registrationMutation.mutate({
            ...state?.payload,
            activityInterest: [],
            plateType: 'web',
            countryCode: state?.payload?.newCountryCode ?? state?.payload?.countryCode,
            mobileNo: state?.payload?.newMobileNo ?? state?.payload?.mobileNo,
            newMobileNo: undefined,
            newCountryCode: undefined,
            confirmPassword: undefined,
        })
    }

    useEffect(() => {
        if (!!data) {
            setInterestList(data)
        }
    }, [data])

    const renderList = matches
        ? interestList?.reduce((pre, next, index) => {
              if (index % 2 === 0) {
                  if (!pre[0]) {
                      pre[0] = []
                  }
                  pre[0].push(next)
              }
              if (index % 2 === 1) {
                  if (!pre[1]) {
                      pre[1] = []
                  }
                  pre[1].push(next)
              }
              return pre
          }, [])
        : interestList?.reduce((pre, next, index) => {
              if (index % 3 === 0) {
                  if (!pre[0]) {
                      pre[0] = []
                  }
                  pre[0].push(next)
              }
              if (index % 3 === 1) {
                  if (!pre[1]) {
                      pre[1] = []
                  }
                  pre[1].push(next)
              }
              if (index % 3 === 2) {
                  if (!pre[2]) {
                      pre[2] = []
                  }
                  pre[2].push(next)
              }
              return pre
          }, [])

    return (
        <Wrap className={`interestWrap ${isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''}`}>
            <Box className='flex justify-between items-center'>
                <Typography variant='h5'>{t('ChooseYourInterest.ChooseYourInterestDisplay')}</Typography>
                <Link variant='subtitle2' component='button' onClick={handleSkip}>
                    {t('ChooseYourInterest.SkipButton')}
                </Link>
            </Box>

            <Typography variant='subtitle2'>{t('ChooseYourInterest.SelectInterests')}</Typography>

            {isPending ? (
                <Loading />
            ) : (
                <Box>
                    {/* <ImageList variant='masonry' cols={matches ? 2 : 3} gap={matches ? 5 : 10} className='p-[4px]'>
                        {interestList.map((item, i) => (
                            <ImageListItemTw key={i} isSelect={item.isSelect} onClick={() => onSelect(item)}>
                                <Img
                                    isSelect={item.isSelect}
                                    srcSet={`${item.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.image}?w=248&fit=crop&auto=format`}
                                    alt={item.name}
                                    loading='lazy'
                                />
                                <Typography className='absolute bottom-2 left-2 font-bold text-white'>
                                    {item?.name}
                                </Typography>
                            </ImageListItemTw>
                        ))}
                    </ImageList> */}
                    <div className={`grid ${matches ? 'grid-cols-2 gap-3' : 'grid-cols-3 gap-x-5'}`}>
                        {renderList?.map((items, index) => {
                            return (
                                <div key={index} className={`flex flex-col ${matches ? 'gap-y-3' : 'gap-y-5'}`}>
                                    {items?.map((item, i) => {
                                        return (
                                            <ImgContainer
                                                className={`relative cursor-pointer border-4 ${
                                                    matches ? 'w-full' : 'max-w-[188px]'
                                                } ${item.isSelect ? 'rounded-2xl border-[red]' : 'border-transparent'}`}
                                                isSelect={item.isSelect}
                                                onClick={() => onSelect(item)}
                                            >
                                                {matches ? (
                                                    <img
                                                        src={item.image}
                                                        alt={item.name}
                                                        loading='lazy'
                                                        className={`w-full ${
                                                            index === 0 && i % 2 === 0 && 'aspect-[1/1]'
                                                        }
                                                            ${index === 0 && i % 2 === 1 && 'aspect-[2/3]'}
                                                            ${index === 1 && i % 2 === 1 && 'aspect-[1/1]'}
                                                        ${index === 1 && i % 2 === 0 && 'aspect-[2/3]'}
                                                        object-cover rounded-xl`}
                                                    />
                                                ) : (
                                                    <img
                                                        src={item.image}
                                                        alt={item.name}
                                                        loading='lazy'
                                                        className={`w-full ${
                                                            index === 1 && i % 2 === 0 && 'aspect-[1/1]'
                                                        }
                                                    ${index === 1 && i % 2 === 1 && 'aspect-[2/3]'}
                                                    ${index !== 1 && 'aspect-[2/3]'}
                                                    object-cover rounded-xl`}
                                                    />
                                                )}
                                                <div
                                                    className={`absolute top-0 right-0 bottom-0 left-0  rounded-xl`}
                                                    style={{
                                                        background: item.isSelect
                                                            ? 'linear-gradient(to bottom, hsla(347, 100%, 46%, 0.2), hsla(231, 46%, 31%, 0.2))'
                                                            : 'rgba(0,0,0,.5)',
                                                    }}
                                                />

                                                <Typography className='absolute bottom-2 left-3 font-bold text-white'>
                                                    {item?.name}
                                                </Typography>
                                            </ImgContainer>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </Box>
            )}

            <Button
                disabled={registrationMutation?.isPending}
                variant='contained'
                size='large'
                onClick={onSubmit}
                className='rounded-full normal-case mt-6 w-full'
            >
                {t('ChooseYourInterest.SubmitButton')} ({length(filter((v) => v.isSelect === true, interestList))})
            </Button>

            <Backdrop open={registrationMutation?.isPending}>
                <CircularProgress className='text-white !w-[76px] !h-[76px]' />
            </Backdrop>
        </Wrap>
    )
}
