import React, { useEffect, useState, useMemo } from 'react'
import tw from 'twin.macro'
import { Typography, Button, useMediaQuery } from '@mui/material'
import { useLocation, useNavigate, useLoaderData, Link, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useEffectOnce } from 'react-use'
import { useSnackbar } from 'notistack'
import { isNil, indexOf } from 'ramda'
import { useTranslation, Trans } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'
import { useOrientation } from 'react-use'
import { useTheme } from '@mui/material/styles'

import { loginReg } from 'service/authService'
import { crm } from 'service/crmService'
import { useLangStore } from 'store/authAtom'
import { displayEmail } from 'utils/filter'
import { ErrorCode } from 'utils/staticData'

const Wrap = tw.div`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-6`

let interval = ''

export default function VerifyEmail() {
    const { t } = useTranslation()
    const [num, setNum] = useState(0)

    const { angle } = useOrientation()
    const theme = useTheme()
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const { lang, setLang } = useLangStore(
        useShallow(({ lang, setLang }) => ({
            lang,
            setLang,
        })),
    )
    const { state } = useLocation()
    const navigate = useNavigate()
    const { emailUuid, sysLang, type, userInfoKey } = useParams()

    const [resendEmail, setResendEmail] = useState(null)

    const verifyEmailResult = useLoaderData()
    const { enqueueSnackbar } = useSnackbar()
    const [countdownValue, setCountdownValue] = useState(60)

    const checkProfileExistMutation = useMutation({
        mutationFn: crm.checkProfileExist,
        onSuccess: () => {
            if (verifyEmailResult?.result?.emailStatus === 'VERIFY') {
                navigate('/login/verifyPhone', {
                    state: {
                        ...verifyEmailResult?.result, ///emailId & forGotPasswordKey & lang & nickName
                        userInfoKey,
                    },
                })
            } else {
                // 请重新send email
                // errorCode -> 92002
                setCountdownValue(0)
                enqueueSnackbar(t('ErrorCode.92002'), { variant: 'warning' })
            }
        },
        onError: () => {
            if (verifyEmailResult?.result?.emailStatus === 'VERIFY') {
                navigate('/login/resetPassword', {
                    state: {
                        payload: {
                            ...verifyEmailResult?.result, ///emailId & forGotPasswordKey & lang & nickName
                        },
                    },
                })
            } else {
                // 请重新send email
                // errorCode -> 92002
                setCountdownValue(0)
                enqueueSnackbar(t('ErrorCode.92002'), { variant: 'warning' })
            }
        },
    })

    const sendEmailMutation = useMutation({
        mutationFn: loginReg.sendEmail, ////gmail
        onSuccess: (res) => {
            setCountdownValue(60)
            interval = setInterval(() => {
                setCountdownValue((prevValue) => prevValue - 1)
            }, 1000)
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })

    const onResend = () => {
        sendEmailMutation.mutate({
            emailId: resendEmail ?? state?.payload?.oldEmailID ?? verifyEmailResult?.result?.emailId,
            newEmail: '',
            lang: state?.payload?.languagePreference || verifyEmailResult?.result?.lang || lang,
            nickName: state?.payload?.nickname || verifyEmailResult?.result?.nickName || '',
            plateType: 'web',
            type:
                (!!state && state?.backRoute !== '/login/forgotPassword') || (!!type && type !== 'forgotPassword')
                    ? 'reg'
                    : 'forgotPassword',
            sysLang: lang,
        })
    }

    const isRegistration = useMemo(
        () => (!!state && state?.backRoute !== '/login/forgotPassword') || (!!type && type !== 'forgotPassword'),
        [state, type],
    )

    // sign up 首发 && update email 首次触发
    useEffect(() => {
        if (isNil(emailUuid) && !state?.pass && num === 1) {
            sendEmailMutation.mutate({
                emailId: state?.payload?.emailId,
                lang: state?.payload?.languagePreference || lang,
                nickName: state?.payload?.nickname || '',
                newEmail: state?.payload?.newEmail || '',
                plateType: 'web',
                type: isRegistration ? 'reg' : 'forgotPassword',
                sysLang: lang,
            })
            if (state?.payload?.newEmail) {
                setResendEmail(state?.payload?.newEmail)
            } else {
                setResendEmail(state?.payload?.emailId)
            }
        } else {
            setCountdownValue(0)
        }
    }, [num, isRegistration])

    useEffectOnce(() => {
        setNum((item) => item + 1)
    })

    useEffect(() => {
        if (!isNil(emailUuid) && sysLang && num === 1) {
            setLang(sysLang)
            if (verifyEmailResult?.errorCode === '00000') {
                checkProfileExistMutation.mutate({
                    emailId: verifyEmailResult?.result?.emailId,
                    lang: sysLang,
                })
            } else {
                if (indexOf(verifyEmailResult?.errorCode, ErrorCode) !== -1) {
                    /// uuid已经找不到用户的信息 -> 92003
                    // window.location.href = `${process.env.REACT_APP_WEBSITE}?lang=${lang}`
                    enqueueSnackbar(t(`ErrorCode.${verifyEmailResult?.errorCode}`), { variant: 'error' })

                    setTimeout(() => {
                        window.location.href = `${process.env.REACT_APP_WEBSITE}?lang=${sysLang}`
                    }, 5000)
                }
            }
        }
    }, [verifyEmailResult, emailUuid, sysLang, num])

    useEffect(() => {
        if (countdownValue === 0) {
            clearInterval(interval) ////清除的話就可以重置点击
        }
    }, [countdownValue])

    return (
        <Wrap
            className={`site-grid site-grid--support-medium min-h-[380px] ${
                isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''
            }`}
        >
            <>
                <Typography variant='h5' gutterBottom>
                    {t(
                        isRegistration
                            ? 'EmailVerification.EmailVerificationDisplay'
                            : 'EmailVerification.ResetYourPassword',
                    )}
                </Typography>

                <Typography variant='subtitle2' gutterBottom>
                    {t(
                        isRegistration
                            ? 'EmailVerification.TitleEmailVerificationDisplay'
                            : 'EmailVerification.TitleResetYourPasswordDisplay',
                        {
                            emailId: displayEmail(
                                state?.payload?.newEmail ??
                                    state?.payload?.emailId ??
                                    verifyEmailResult?.result?.emailId,
                            ),
                        },
                    )}
                </Typography>

                {isRegistration && (
                    <Typography variant='subtitle2' gutterBottom>
                        <Trans
                            i18nKey='EmailVerification.DesEmailVerificationDisplay'
                            components={[
                                <Link
                                    to={`/login/updateEmail`}
                                    state={
                                        !!verifyEmailResult?.result
                                            ? {
                                                  ...verifyEmailResult?.result,
                                                  oldEmailID:
                                                      resendEmail ??
                                                      state?.payload?.emailId ??
                                                      verifyEmailResult?.result?.emailId,
                                              }
                                            : {
                                                  ...state?.payload,
                                                  oldEmailID:
                                                      resendEmail ??
                                                      state?.payload?.emailId ??
                                                      verifyEmailResult?.result?.emailId,
                                              }
                                    }
                                    className='text-primaryRed-ktRed underline'
                                />,
                            ]}
                        />
                    </Typography>
                )}

                <Button
                    disabled={countdownValue !== 0}
                    variant='contained'
                    size='large'
                    className='rounded-full normal-case'
                    fullWidth
                    onClick={onResend}
                >
                    {t(
                        isRegistration
                            ? 'EmailVerification.ResendButton'
                            : 'EmailVerification.ResetPasswordResendButton',
                    )}
                </Button>
                <Typography variant='subtitle2' className='text-center mt-5'>
                    （{countdownValue}s）
                </Typography>
            </>
        </Wrap>
    )
}
