import { useState, useMemo } from 'react'
import { TextField, Box, Button, Typography, InputAdornment, IconButton, useMediaQuery } from '@mui/material'
import tw from 'twin.macro'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useOrientation } from 'react-use'
import { useTheme } from '@mui/material/styles'

import { user } from 'service/userService'
import { useLangStore } from 'store/authAtom'
import { useValidationI18n } from 'utils/validationI18n'
import { useUpdateErrorMessages } from 'utils/filter'

import ViewOff from 'resources/svg/view_off.svg'
import View from 'resources/svg/view.svg'
import { encrypt_signUp } from 'utils/crypto'

const Wrap = tw.div`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-8`
const Form = tw.form`space-y-8 w-full mt-3`
const ImgIcon = tw.img``

export default function ResetPassword() {
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate()
    let { state } = useLocation()
    const lang = useLangStore((state) => state.lang)
    const { validationSchema } = useValidationI18n()

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [focus, setFocus] = useState(false)

    const handleFocus = () => {
        setFocus(true)
    }

    const handleBlur = () => {
        setFocus(false)
    }

    const { angle } = useOrientation()
    const theme = useTheme()
    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const resetPasswordMutation = useMutation({
        mutationFn: user.resetPassword,
        onSuccess: () => {
            enqueueSnackbar(t('ResetPassword.PasswordResetSuccessful'), { variant: 'success' })
            navigate('/login/signIn', {
                replace: true,
            })
        },
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm({
        resolver: yupResolver(
            yup.object({
                password: validationSchema.password,
                confirmPassword: validationSchema.confirmPassword,
            }),
        ),
    })

    useUpdateErrorMessages(errors, trigger)

    const onSubmit = (formData) => {
        resetPasswordMutation.mutate({
            emailId: state?.payload?.emailId ? encrypt_signUp(state?.payload?.emailId) : null,
            lang,
            countryCode: state?.payload?.countryCode,
            mobileNo: state?.payload?.mobileNo ? encrypt_signUp(state?.payload?.mobileNo) : null,
            newPassword: encrypt_signUp(formData?.confirmPassword),
            forGotPasswordKey: encrypt_signUp(state?.payload?.forGotPasswordKey),
        })
    }

    const tips = [
        t('SignUpFormErrorMessage.Characters'),
        t('SignUpFormErrorMessage.Number'),
        t('SignUpFormErrorMessage.Uppercase'),
        t('SignUpFormErrorMessage.Lowercase'),
        t('SignUpFormErrorMessage.Symbol'),
    ]

    return (
        <Wrap
            className={`site-grid site-grid--support-medium ${focus ? '' : 'min-h-[476px]'} ${
                isHorizontal && !matchesLaptopUp ? 'isHorizontal' : ''
            }`}
        >
            <Typography variant='h5' gutterBottom>
                {t('ResetPassword.ResetPasswordDisplay')}
            </Typography>

            <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                <TextField
                    {...register('password')}
                    fullWidth
                    label={t('ResetPassword.PasswordDisplay')}
                    variant='standard'
                    type={showPassword ? 'text' : 'password'}
                    color='secondary'
                    placeholder={t('ResetPassword.PasswordHint')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors?.password}
                    helperText={errors?.password?.message ?? null}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end' className='w-[50px]'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {!showPassword ? <ImgIcon src={ViewOff} /> : <ImgIcon src={View} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    focused={focus}
                />
                {focus ? (
                    <Box>
                        {tips.map((tip, index) => {
                            return (
                                <Box key={index} sx={{ fontSize: 14, fontWeight: 500 }} component='li'>
                                    {tip}
                                </Box>
                            )
                        })}
                    </Box>
                ) : null}

                <TextField
                    {...register('confirmPassword')}
                    fullWidth
                    label={t('ResetPassword.ConfirmPasswordDisplay')}
                    variant='standard'
                    type={showConfirmPassword ? 'text' : 'password'}
                    color='secondary'
                    placeholder={t('ResetPassword.PasswordHint')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors?.confirmPassword}
                    helperText={errors?.confirmPassword?.message ?? null}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end' className='w-[50px]'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowConfirmPassword((show) => !show)}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {!showConfirmPassword ? <ImgIcon src={ViewOff} /> : <ImgIcon src={View} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Button variant='contained' size='large' className='rounded-full normal-case' fullWidth type='submit'>
                    {t('ResetPassword.ResetPasswordButton')}
                </Button>
                <Button
                    className='rounded-full normal-case mt-6'
                    variant='outlined'
                    fullWidth
                    onClick={() => navigate(-1)}
                >
                    {t('ResetPassword.BackButton')}
                </Button>
            </Form>
        </Wrap>
    )
}
