import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import Main from 'page/Main'

import Login from 'page/Login'
import SignIn from 'page/Login/SignIn'
import SignUp from 'page/Login/SignUp'
import VerifyEmail from 'page/Login/VerifyEmail'
import VerifyPhone from 'page/Login/VerifyPhone'
import UpdatePhone from 'page/Login/UpdatePhone'
import UpdateEmail from 'page/Login/UpdateEmail'
import ChooseYourInterest from 'page/Login/ChooseYourInterest'
import ForgotPassword from 'page/Login/ForgotPassword'
import ResetPassword from 'page/Login/ResetPassword'

import NoRecordFound from 'page/IAMSmart/NoRecordFound'
import ConsentPage from 'page/IAMSmart/ConsentPage'
import InstructionPage from 'page/IAMSmart/InstructionPage'
import BindIAM from 'page/IAMSmart/BindIAM'
import CallBackRedirectURI from 'page/IAMSmart/CallBackRedirectURI'

import { BASE_URL } from 'utils/http'
import Error from 'page/Main/Error'

const ktspUrl = process.env.NODE_ENV === 'development' ? BASE_URL : process.env.REACT_APP_BASE_URL + BASE_URL
const langs = ['en_US', 'zh_CN', 'zh_HK']
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Main />} errorElement={<Error />}>
            <Route index element={<Navigate to='login' replace />} />
            <Route path='login' element={<Login />}>
                <Route index element={<Navigate to='signIn' replace />} />
                <Route path='signIn' element={<SignIn />} />
                <Route path='signUp' element={<SignUp />} />
                <Route path='verifyEmail' element={<VerifyEmail />} />
                <Route
                    path='verifyEmail/:emailUuid/:lang/:type/:sysLang/:userInfoKey/:emailId'
                    element={<VerifyEmail />}
                    loader={({ params }) => {
                        const sysLang = langs.includes(params.sysLang) ? params.sysLang : 'en_US'
                        return new Promise((resolve, reject) => {
                            fetch(`${ktspUrl}/loginReg/emailVerifiy`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    emailUuid: params.emailUuid,
                                    emailId: decodeURIComponent(params.emailId),
                                    lang: params.sysLang,
                                }),
                            })
                                .then((res) => {
                                    if (res.ok) {
                                        return res.json()
                                    } else {
                                        throw res
                                    }
                                })
                                .then((v) => {
                                    resolve(v)
                                })
                                .catch((err) => {
                                    reject(err)
                                })
                        })
                    }}
                />

                <Route path='verifyPhone' element={<VerifyPhone />} />
                <Route path='updatePhone' element={<UpdatePhone />} />
                <Route path='updateEmail' element={<UpdateEmail />} />
                <Route path='chooseYourInterest' element={<ChooseYourInterest />} />
                <Route path='forgotPassword' element={<ForgotPassword />} />
                <Route path='resetPassword' element={<ResetPassword />} />
            </Route>
            <Route path='callBackRedirectURI' element={<CallBackRedirectURI />} />
            <Route path='noRecordFound' element={<NoRecordFound />} />
            <Route path='consentPage' element={<ConsentPage />} />
            <Route path='instructionPage' element={<InstructionPage />} />
            <Route path='bindIAM' element={<BindIAM />} />
        </Route>,
    ),
)

export default function App() {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <title>{t('Common.Title')}</title>
            </Helmet>
            <RouterProvider router={router} />
        </>
    )
}
