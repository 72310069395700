import React, { useEffect } from 'react'
import { MenuItem, SvgIcon } from '@mui/material'
import { Select } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useLangStore } from 'store/authAtom'

export const languageList = [
    {
        value: 'en_US',
        label: 'EN',
    },
    {
        value: 'zh_HK',
        label: '繁體',
    },
    {
        value: 'zh_CN',
        label: '简体',
    },
]

function SelectComponent({ color = '#fff' }) {
    const { i18n } = useTranslation()

    const { setLang: setLangStore, lang: langStore } = useLangStore(
        useShallow(({ setLang, lang }) => ({
            lang,
            setLang,
        })),
    )

    const handleChange = (e) => {
        setLangStore(e.target.value)
        i18n.changeLanguage(e.target.value)
        window.localStorage.setItem('lang', e.target.value)

        const currentUrl = window.location.href
        const url = new URL(currentUrl)
        const searchParams = new URLSearchParams(url.search)
        if (searchParams.get('lang')) {
            searchParams.delete('lang')
            const newUrl = `${url.origin}${url.pathname}?${searchParams.toString()}`
            window.history.replaceState({}, '', newUrl)
        }
    }

    useEffect(() => {
        i18n.changeLanguage(langStore)
        window.localStorage.setItem('lang', langStore)
    }, [langStore])

    return (
        <Select
            value={langStore ?? 'en_US'}
            onChange={handleChange}
            autoWidth
            sx={{
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '0px',
                    },
                },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: 0,
                },
                '.MuiSvgIcon-root': { color },
                '.MuiSelect-select': { padding: 0 },
                fontSize: 14,
                color,
            }}
        >
            {languageList.map((lan, index) => {
                return (
                    <MenuItem key={lan.value} value={lan.value}>
                        {lan.label}
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default SelectComponent
