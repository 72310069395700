import { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import tw, { styled } from 'twin.macro'
import { useOrientation } from 'react-use'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import Cookies from 'js-cookie'

import { useLangStore } from 'store/authAtom'
import AppBar from 'page/Login/component/AppBar'
import login1440 from 'resources/images/login1440.jpg'
import login768 from 'resources/images/login768.jpg'
import login1024 from 'resources/images/login1024.jpg'
import login1944 from 'resources/images/login1944.png'

import { RemoveCookie } from 'utils/filter'

const Wrap = styled.div`
    ${tw`h-screen w-screen overflow-x-hidden overflow-y-auto flex items-stretch flex-col`}
    background-image: url(${({ matchesMD, matchesLG, matchesXL }) =>
        matchesMD ? login768 : matchesLG ? login1024 : matchesXL ? login1440 : login1944});
    background-size: ${({ isHorizontal, matchesSM, matchesLaptopUp }) =>
        (isHorizontal && !matchesLaptopUp) || matchesSM ? 'auto' : 'cover'};
    background-position: ${({ isHorizontal, matchesLaptopUp, matchesSM }) =>
        (isHorizontal && !matchesLaptopUp) || matchesSM ? 'auto' : 'left bottom'};
`

export default function Main() {
    const theme = useTheme()

    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
    const matchesLG = useMediaQuery(theme.breakpoints.down('lg'))
    const matchesXL = useMediaQuery(theme.breakpoints.down('xl'))

    const matchesLaptopUp = useMediaQuery(theme.breakpoints.up('flatbed'))

    const { search } = useLocation()
    const { angle } = useOrientation()

    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    const { setLang, setRedirectUrl } = useLangStore(
        useShallow(({ setLang, setRedirectUrl }) => ({
            setLang,
            setRedirectUrl,
        })),
    )

    const typeMemo = useMemo(() => {
        return {
            isHorizontal,
            matchesSM,
            matchesMD,
            matchesLG,
            matchesLaptopUp,
            matchesXL,
        }
    }, [isHorizontal, matchesSM, matchesMD, matchesLG, matchesLaptopUp, matchesXL])

    useEffect(() => {
        const params = new URLSearchParams(search)

        if (params.get('lang') && params.get('redirectUrl')) {
            setLang(params.get('lang'))
            setRedirectUrl(params.get('redirectUrl'))
        }

        if (Cookies.get('lang')) {
            setLang(Cookies.get('lang'))
            setTimeout(() => {
                RemoveCookie('lang')
            }, 3000)
        }
    }, [search, Cookies.get('lang')])

    return (
        <Wrap {...typeMemo}>
            <AppBar />
            <div className='flex-1 overflow-auto'>
                <Outlet />
            </div>
        </Wrap>
    )
}
