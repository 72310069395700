import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// 导入语言资源文件
import translationEN from './en.json'
import translationZHCN from './zhCN.json'
import translationZHHK from './zhHK.json'

// 创建 i18next 实例
i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en_US: { translation: translationEN },
            zh_CN: { translation: translationZHCN },
            zh_HK: { translation: translationZHHK },
        },
        // lng: 'en_US', ///动态改语言
        fallbackLng: 'en_US', // 如果没有加载语言的资源则使用此语言
        interpolation: { escapeValue: false }, // React 已经安全地对值进行编码
    })

export default i18n
