import { useMemo } from 'react'
import tw from 'twin.macro'
import { Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useOrientation } from 'react-use'
import { useTranslation } from 'react-i18next'

const Wrap = tw.div`self-center px-[60px] py-[42px] my-[60px] flex flex-col bg-white shadow-lg space-y-8`

export default function BindIAM() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { angle } = useOrientation()
    const isHorizontal = useMemo(() => (angle === 90 ? true : false), [angle])

    return (
        <Wrap className={`site-grid site-grid--support-medium mx-auto min-h-[256px] ${isHorizontal ? 'isHorizontal' : ''}`}>
            <Typography variant='h5'> {t('LoginWithIAMSmart.Display02')}</Typography>

            <Typography variant='subtitle2'>{t('LoginWithIAMSmart.Display03')}</Typography>

            <Button
                variant='contained'
                className='rounded-full normal-case mt-6'
                fullWidth
                onClick={() => navigate('/login/signIn')}
            >
                {t('LoginWithIAMSmart.Button03')}
            </Button>
        </Wrap>
    )
}
