import tw from 'twin.macro'
import { Outlet } from 'react-router-dom'

const Wrap = tw.div`relative h-[calc(100vh - 108px)] w-full overflow-y-auto flex items-stretch flex-col`

export default function Main() {
    return (
        <Wrap>
            <Outlet />
        </Wrap>
    )
}
