import React from 'react'
import { CircularProgress } from '@mui/material'

export default function Loading() {
    return (
        <div className='flex justify-center items-center h-[300px] flex-1'>
            <CircularProgress />
        </div>
    )
}
